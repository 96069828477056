import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './simple-input-dialog.component.html',
})
export class SimpleInputDialog implements OnInit {
    inputType = this.data.isNumber ? 'number' : 'text';
    form = new FormGroup({ input: new FormControl() });

    constructor(
        public dialogRef: MatDialogRef<SimpleInputDialog>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.form.get('input').setValue(this.data.input ?? '');
    }

    handleSave() {
        this.dialogRef.close(this.form.get('input').value);
    }
}
