<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <form
        [formGroup]="form"
        class="flex flex-col gap-2"
        (ngSubmit)="handleSave()"
    >
        <div *ngIf="!!data.text">{{ data.text | translate }}</div>
        <div class="mat-subtitle-2" *ngIf="!!data.label">
            {{ data.label | translate }}
        </div>
        <sz-input [type]="inputType" formControlName="input" />
        <div *ngIf="data.description" subscriptSizing="dynamic" class="text-xs">
            {{ data.description | translate }}<br />
            <a
                *ngIf="data.linkPath"
                class="text-primary-500"
                [href]="data.linkPath"
                target="_blank"
                (click)="dialogRef.close()"
                >{{ data.linkDescription | translate }}</a
            >
        </div>
    </form>
    <sz-dialog-button-bar
        [primaryDisabled]="!this.form.get('input').value.trim()"
        (primaryClick)="handleSave()"
        (secondaryClick)="dialogRef.close()"
    />
</div>
